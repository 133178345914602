<template>
  <div v-if="options" class="toggleSectionContainer">
    <div class="labelContainer">
      {{ label }}
    </div>
    <div
      class="toggleOptionContainer"
      :style="{ width: `${itemWidth * options.length}%` }"
    >
      <div
        v-for="(t, i) in options"
        :key="i"
        @click="toggleOptionClick(t)"
        :style="{
          width: `${itemWidth}%`,
          backgroundColor: selectedOption === t ? 'white' : '#343434',
          color: selectedOption === t ? '#343434' : 'white',
        }"
      >
        {{ t }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleBar",
  props: ["options", "label", "defaultVal"],
  data() {
    return {
      selectedOption: this.defaultVal || (this.options && this.options[0]),
    };
  },
  methods: {
    toggleOptionClick(option) {
      // Check if there are only two options
      if (this.options.length === 2) {
        // Determine the option that is not currently selected
        const otherOption = this.options.find(
          (opt) => opt !== this.selectedOption
        );
        // Set the selected option to the other option
        this.selectedOption = otherOption;
        // Emit the selected option
        this.$emit("option-clicked", this.selectedOption);
      } else {
        // Toggle the selected option
        this.selectedOption = option;
        // Emit the selected option
        this.$emit("option-clicked", option);
      }
    },
  },
  computed: {
    itemWidth() {
      return 100 / this.options.length;
    },
  },
};
</script>

<style scoped>
.toggleOptionContainer {
  display: flex;
  border: 3px solid #343434;
  background-color: #343434;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
}
.toggleOptionContainer div {
  padding: 6px;
  text-align: center;
  z-index: 1;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 20px;
}
.toggleSectionContainer {
  display: flex;
  align-items: center;
  font-weight: bold;
  width: 45%;
  color: white;
  justify-content: space-evenly;
}
.labelContainer {
  margin: 0 10px 0 10px;
  width: 30%;
}
</style>
