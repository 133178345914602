<template>
  <div>
    <edge-graph-section
      class="fullScreenGraphContainer"
      :edge-data="this.thisEdgeData"
    />
  </div>
</template>

<script>
import EdgeGraphSection from "@/components/EdgeGraphSection.vue";

export default {
  name: "EdgeGraphFull",
  components: {
    EdgeGraphSection,
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  computed: {
    thisEdgeData() {
      return this.$store.state.selectedEdge;
    },
  },
  methods: {
    handleKeyUp(event) {
      if (event.key === "Escape") {
        window.removeEventListener("keyup", this.handleKeyUp);
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
.fullScreenGraphContainer {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: visible;
  flex-grow: 3;
  background-color: rgba(0, 0, 0, 0.6);
}
</style>
