<template>
  <div v-if="this.$store.state.loading" class="loading-bar">
    <div class="loading-bar-progress"></div>
  </div>
</template>

<style>
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #00bfff;
  z-index: 9999;
  overflow: hidden;
}

.loading-bar-progress {
  height: 100%;
  background-color: #cbff00;
  animation: bounce 2s linear infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
}
</style>
