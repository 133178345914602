<template>
  <div id="app">
    <app-header v-if="!isFullScreenGraph" />
    <body>
      <div
        class="flexItem"
        :style="{
          paddingTop: !noSearchText && !searchButNoResult ? '0px' : '50px',
        }"
      >
        <search-bar v-if="noSearchText || searchButNoResult"></search-bar>
        <router-view id="router-view" :key="this.$route.fullPath"></router-view>
      </div>
    </body>
    <app-footer v-if="!isFullScreenGraph" />
  </div>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "App",
  components: { AppFooter, AppHeader, SearchBar },
  beforeCreate() {
    this.$store.dispatch("fetchGraph");
    this.$store.dispatch("fetchUsdPrice");
  },
  watch: {
    "$route.params.pubkey": {
      handler(newValue) {
        if (newValue) {
          this.$store.dispatch(
            "setSearchedNodePubkey",
            this.$route.params.pubkey
          );
          this.$store.dispatch("fetchNodeByPubkey");
        }
      },
      deep: true, // Watch for changes deeply in the object
    },
    "$route.params.chanId": {
      handler(newValue) {
        if (newValue) {
          this.$store.dispatch("setSearchedChanId", this.$route.params.chanId);
          this.$store.dispatch("fetchSearchedEdgeByChanId");
        }
      },
      deep: true, // Watch for changes deeply in the object
    },
    "$store.state.searchedNode": {
      handler(newValue) {
        // Check if searchedNode is defined and has a 'pubkey' property
        if (newValue && newValue.pubkey) {
          // Call your Vuex store method here
          const defaultDays = this.$store.state.nodeHistToggleOptions[0];

          // this.$store.dispatch("fetchNodeBasicHistoryByPubkey");
          this.$store.dispatch("selectNodeHistoryDays", defaultDays);
          this.$store.dispatch("fetchNodeHistoryByPubkey", defaultDays);
          this.$store.dispatch("fetchNodePeersByPubkey");

          if (
            (newValue.pubkey !== this.$store.state.searchedPubkey &&
              newValue.pubkey !== this.$route.params.pubkey) ||
            !this.$route.params.pubkey
          ) {
            this.$router.push({
              name: "node",
              params: { pubkey: this.$store.state.searchedNode.pubkey },
            });
          }
        }
      },
      deep: true, // Watch for changes deeply in the object
    },
    "$store.state.searchedEdge": {
      handler(newValue) {
        // Check if searchedNode is defined and has a 'channelId' property
        if (newValue && newValue.channelId) {
          const defaultDays = this.$store.state.edgeHistToggleOptions[0];

          this.$store.dispatch("selectEdgeHistoryDays", defaultDays);
          this.$store.dispatch("fetchEdgeHistoryByChanId", defaultDays);
          if (
            (newValue.channelId !== this.$store.state.searchedChanId &&
              newValue.channelId !== this.$route.params.chanId) ||
            !this.$route.params.chanId
          ) {
            this.$router.push({
              name: "channel",
              params: { chanId: this.$store.state.searchedEdge.channelId },
            });
          }
        }
      },
      deep: true, // Watch for changes deeply in the object
    },
  },

  computed: {
    userAgent() {
      return navigator.userAgent;
    },
    isFullScreenGraph() {
      return (
        !this.noSearchText &&
        !this.searchButNoResult &&
        this.$store.state.searchedNode &&
        this.$store.state.searchedNode.pubkey &&
        this.$store.state.searchedNode.pubkey.trim() !== "" &&
        this.$route.path ===
          "/node/full/" + this.$store.state.searchedNode.pubkey
      );
    },
    noSearchText() {
      return (
        this.$store.state.searchedPubkey === "" &&
        this.$store.state.searchedAlias === "" &&
        this.$store.state.searchedChanId === ""
      );
    },
    searchButNoResult() {
      let pubKeyNotBlank, aliasNotBlank, chanIdNotBlank, nodeUndef, edgeUndef;
      pubKeyNotBlank = !!this.$store.state.searchedPubkey;
      aliasNotBlank = !!this.$store.state.searchedAlias;
      chanIdNotBlank = !!this.$store.state.searchedChanId;
      nodeUndef = this.$store.state.searchedNode === undefined;
      edgeUndef = this.$store.state.searchedEdge === undefined;
      return (
        (pubKeyNotBlank || aliasNotBlank || chanIdNotBlank) &&
        nodeUndef &&
        edgeUndef
      );
    },
  },
  provide: {
    searchedNodeExists() {
      return (
        !!this.$store.state.searchedNode &&
        !!this.$store.state.searchedNode.pubkey &&
        this.$store.state.searchedNode.pubkey !== ""
      );
    },
    searchedEdgeExists() {
      return (
        !!this.$store.state.searchedEdge &&
        !!this.$store.state.searchedEdge.channelId &&
        this.$store.state.searchedEdge.channelId !== ""
      );
    },
    isNumber(val) {
      return !isNaN(parseFloat(val)) && isFinite(val);
    },
    isNodeRoute() {
      return this.$route.name === "node";
    },
    isChannelRoute() {
      return this.$route.name === "channel";
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    calculateEdgeWidth(capacity, minCapacity, maxCapacity) {
      let edgeWidth = 3;
      if (maxCapacity !== minCapacity) {
        edgeWidth =
          6 + ((capacity - minCapacity) / (maxCapacity - minCapacity)) * 5;
      }
      return edgeWidth;
    },
    calculateNodeRadius(capacity, minCapacity, maxCapacity) {
      let nodeRadius = 10;
      if (maxCapacity !== minCapacity) {
        const range = maxCapacity - minCapacity;
        const normalizedCapacity = (capacity - minCapacity) / range;

        // Use a quadratic function to increase variation
        nodeRadius = 20 + normalizedCapacity * 24;
      }
      return nodeRadius;
    },
    isFullScreenGraph() {
      const result =
        !this.noSearchText &&
        !this.searchButNoResult &&
        this.$store.state.searchedNode &&
        this.$store.state.searchedNode.pubkey &&
        this.$store.state.searchedNode.pubkey.trim() !== "" &&
        this.$route.path ===
          "/node/full/" + this.$store.state.searchedNode.pubkey;
      return result;
    },
    noSearchText() {
      return (
        this.$store.state.searchedPubkey === "" &&
        this.$store.state.searchedAlias === "" &&
        this.$store.state.searchedChanId === ""
      );
    },
    searchButNoResult() {
      let pubKeyNotBlank, aliasNotBlank, chanIdNotBlank, nodeUndef, edgeUndef;
      pubKeyNotBlank = !!this.$store.state.searchedPubkey;
      aliasNotBlank = !!this.$store.state.searchedAlias;
      chanIdNotBlank = !!this.$store.state.searchedChanId;
      nodeUndef = this.$store.state.searchedNode === undefined;
      edgeUndef = this.$store.state.searchedEdge === undefined;
      return (
        (pubKeyNotBlank || aliasNotBlank || chanIdNotBlank) &&
        nodeUndef &&
        edgeUndef
      );
    },
    convertMillisatToSat(mSat) {
      return Number(mSat) / 1000;
    },
    convertSatsToUsd(sats) {
      const btc = sats / 100000000;
      const usd = btc * this.$store.state.usdPrice;
      return "$" + this.getNumberFormattedCommas(usd.toFixed(2));
    },
    getNumberFormattedCommas(number) {
      return Number(number).toLocaleString();
    },
    getTimeSinceLastUpdate(lastUpdate) {
      if (!lastUpdate) {
        return "..."; // Handle the case when lastUpdate is not provided
      }
      const currentTime = new Date();
      const lastUpdateDate = new Date(lastUpdate);
      const timeDifferenceInSeconds = Math.floor(
        (currentTime - lastUpdateDate) / 1000
      );
      const days = Math.floor(timeDifferenceInSeconds / (60 * 60 * 24));
      const hours = Math.floor(
        (timeDifferenceInSeconds % (60 * 60 * 24)) / 3600
      );
      const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);

      let timeSinceLastUpdate = "";

      if (days > 30) {
        timeSinceLastUpdate = `${Math.floor(days / 30)} months `;
      } else if (days >= 1) {
        timeSinceLastUpdate = `${days} days `;
      } else if (hours >= 1) {
        timeSinceLastUpdate = `${hours} hours `;
      } else {
        timeSinceLastUpdate = `${minutes} minutes `;
      }

      if (timeSinceLastUpdate === "") {
        return "...";
      } else {
        return timeSinceLastUpdate + "ago";
      }
    },
    shortenPubkey(pubkey) {
      let pubkeyLen;
      pubkeyLen = pubkey.length;
      return (
        pubkey.substring(0, 6) +
        "..." +
        pubkey.substring(pubkeyLen - 6, pubkeyLen)
      );
    },
    setNodeHistoryDays(days) {
      this.$store.dispatch("selectNodeHistoryDays", days);
    },
    setNodeHistoryDaysReloadGraphs(days) {
      if (days === this.$store.state.nodeHistoryDays) {
        return; // Prevent reloading if the same day num is selected
      }
      this.setNodeHistoryDays(days);
      this.$store.dispatch("fetchNodeHistoryByPubkey", days);
    },
    setEdgeHistoryDays(days) {
      this.$store.dispatch("selectEdgeHistoryDays", days);
    },
    setEdgeHistoryDaysReloadGraphs(days) {
      if (days === this.$store.state.edgeHistoryDays) {
        return; // Prevent reloading if the same day num is selected
      }
      this.setEdgeHistoryDays(days);
      this.$store.dispatch("fetchEdgeHistoryByChanId", days);
    },
    convertPeersToDisplayChannels(peers) {
      const node1Key = this.$store.state.searchedNode.pubkey;

      const nodes = {};
      const edges = {};
      // width and radius
      const minEdgeCapacity = Math.min(...peers.map((peer) => peer.capacity));
      const maxEdgeCapacity = Math.max(...peers.map((peer) => peer.capacity));

      const minNodeCapacity = Math.min(
        ...peers.map((peer) => peer.peerCapacity)
      );
      const maxNodeCapacity = Math.max(
        ...peers.map((peer) => peer.peerCapacity)
      );

      peers.forEach((peer, index) => {
        const node2Key = peer.peerPub || `peer${index + 1}`;
        const edgeKey = peer.channelId || `edge${index + 1}`;
        const edgeCapacity = peer.capacity;
        const peerCapacity = peer.peerCapacity;

        const edgeWidth = this.calculateEdgeWidth(
          edgeCapacity,
          minEdgeCapacity,
          maxEdgeCapacity
        );

        const nodeRadius = this.calculateNodeRadius(
          peerCapacity,
          minNodeCapacity,
          maxNodeCapacity
        );

        nodes[node1Key] = {
          name: this.$store.state.searchedNode.alias || `Alias ${index + 1}`,
          radius: nodeRadius,
        };
        nodes[node2Key] = {
          name:
            peer.peerAlias ||
            this.shortenPubkey(peer.peerPub) ||
            `Alias ${index + 2}`,
          radius: nodeRadius,
        };

        edges[edgeKey] = {
          source: node1Key,
          target: node2Key,
          width: edgeWidth,
          label: this.getNumberFormattedCommas(edgeCapacity),
        };
      });
      this.$store.dispatch("setGraphNodes", nodes);
      this.$store.dispatch("setGraphEdges", edges);
    },
    convertEdgesToDisplayChannels(edges) {
      // width and radius
      const thisGraphEdges = this.$store.state.graphEdges;
      const minEdgeCapacity = Math.min(...edges.map((edge) => edge.capacity));
      const maxEdgeCapacity = Math.max(...edges.map((edge) => edge.capacity));

      edges.forEach((edge, index) => {
        const node1Key = edge.node1Pub || `node${index + 1}`;
        const node2Key = edge.node2Pub || `node${index + 2}`;
        const edgeKey = edge.channelId || `edge${index + 1}`;
        const edgeCapacity = edge.capacity;

        const edgeWidth = this.calculateEdgeWidth(
          edgeCapacity,
          minEdgeCapacity,
          maxEdgeCapacity
        );

        thisGraphEdges[edgeKey] = {
          source: node1Key,
          target: node2Key,
          width: edgeWidth,
          label: this.getNumberFormattedCommas(edgeCapacity),
        };
        this.$store.dispatch("setGraphEdges", thisGraphEdges);
      });
    },
    nodeHistFields() {
      return [
        {
          name: "channelCount",
          label: "Channel Count",
          category: "",
          active: true,
        },
        { name: "capacity", label: "Capacity", category: "", active: true },
      ];
    },
    edgeFields() {
      return [
        { name: "channelId", label: "Channel ID", category: "", active: true },
        { name: "capacity", label: "Capacity", category: "", active: true },
        {
          name: "lastUpdate",
          label: "Last Update",
          category: "",
          active: true,
        },
        {
          name: "node1Alias",
          label: "Peer Alias",
          category: "peer",
          active: true,
        },
        {
          name: "node1Capacity",
          label: "Peer Capacity",
          category: "peer",
          active: true,
        },
        {
          name: "node1Pub",
          label: "Peer Public Key",
          category: "peer",
          active: true,
        },
        {
          name: "node1TimeLockDelta",
          label: "Peer Time Lock Delta",
          category: "peer",
          active: true,
        },
        {
          name: "node1MinHtlc",
          label: "Peer Min HTLC",
          category: "peer",
          active: true,
        },
        {
          name: "node1FeeBaseMsat",
          label: "Peer Base Fee",
          category: "peer",
          active: true,
        },
        {
          name: "node1FeeRateMilliMsat",
          label: "Peer Fee Rate",
          category: "peer",
          active: true,
        },
        {
          name: "node1Disabled",
          label: "Peer Disabled",
          category: "peer",
          active: false,
        },
        {
          name: "node1MaxHtlcMsat",
          label: "Peer Max HTLC",
          category: "peer",
          active: true,
        },
        {
          name: "node1LastUpdate",
          label: "Peer Last Update",
          category: "peer",
          active: false,
        },
        {
          name: "node2Alias",
          label: "Alias",
          category: "selected",
          active: true,
        },
        {
          name: "node2Capacity",
          label: "Capacity",
          category: "selected",
          active: true,
        },
        {
          name: "node2Pub",
          label: "Public Key",
          category: "selected",
          active: true,
        },
        {
          name: "node2TimeLockDelta",
          label: "Time Lock Delta",
          category: "selected",
          active: true,
        },
        {
          name: "node2MinHtlc",
          label: "Min HTLC",
          category: "selected",
          active: true,
        },
        {
          name: "node2FeeBaseMsat",
          label: "Base Fee",
          category: "selected",
          active: true,
        },
        {
          name: "node2FeeRateMilliMsat",
          label: "Fee Rate",
          category: "selected",
          active: true,
        },
        {
          name: "node2Disabled",
          label: "Disabled",
          category: "selected",
          active: false,
        },
        {
          name: "node2MaxHtlcMsat",
          label: "Max HTLC",
          category: "selected",
          active: true,
        },
        {
          name: "node2LastUpdate",
          label: "Last Update",
          category: "selected",
          active: false,
        },
      ];
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.flexItem {
  padding: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
body {
  margin: 0;
  padding: 0;
  background-image: url("~@/assets/quiche_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* This keeps the background fixed while scrolling */
}
</style>
