<template>
  <div class="searchBarContainer">
    <div class="searchBarWrapper">
      <input
        id="searchBar"
        ref="searchBarRef"
        type="text"
        v-model="search"
        @keyup.enter="searchResults(search)"
        placeholder="Enter pubkey, alias, or channel ID..."
        maxlength="66"
      />
    </div>
    <button class="btn" @click="searchResults(search)">Search</button>
    <button id="randBtn" @click="selectRandomNode()">
      <img
        id="randIcon"
        src="../assets/icon-random.png"
        alt="random node"
        title="random node"
      />
    </button>
    <div>
      <loading-bar ref="loadingBar"></loading-bar>
    </div>
  </div>
</template>

<script>
import LoadingBar from "./LoadingBar.vue";

export default {
  name: "SearchBar",
  components: {
    LoadingBar,
  },
  inject: ["isNumber"],
  data() {
    return {
      search: "",
    };
  },
  mounted() {
    this.$refs.searchBarRef.focus();
  },
  methods: {
    thisIsNumber(val) {
      return this.isNumber(val);
    },
    selectRandomNode() {
      this.$store.dispatch("clearAllSearchResults");
      this.fetchRandomNode();
    },
    searchResults(searchTerm) {
      if (searchTerm.length > 0) {
        this.$store.dispatch("clearAllSearchResults");
        this.$store.dispatch("setSearchText", searchTerm);

        if (searchTerm.length >= 66) {
          this.$store.dispatch("setSearchedNodePubkey", searchTerm);
          this.fetchNodeByPubkey();
        } else if (searchTerm.length === 18 && this.thisIsNumber(searchTerm)) {
          this.$store.dispatch("setSearchedChanId", searchTerm);
          this.fetchEdgeByChanId();
        } else {
          this.$store.dispatch("setSearchedNodeAlias", searchTerm);
          this.fetchNodeByAlias();
        }
      }
    },
    fetchRandomNode() {
      this.$store.dispatch("fetchRandomNode");
    },
    fetchNodeByPubkey() {
      this.$store.dispatch("fetchNodeByPubkey");
    },
    fetchNodeByAlias() {
      this.$store.dispatch("fetchNodeByAlias");
    },
    fetchEdgeByChanId() {
      this.$store.dispatch("fetchSearchedEdgeByChanId");
    },
  },
};
</script>

<style scoped>
.btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  background-color: #007bff;
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 20px;
  border: none;
  transition: background-color 0.15s ease-in-out;
}
.btn:hover {
  background-color: #0056b3;
}
#randBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #cccccc;
  line-height: 1.5;
  min-width: 40px;
  border-radius: 20px;
  transition: background-color 0.15s ease-in-out; /* Remove other transition properties */
  border: none; /* Remove border */
}

#randBtn:hover {
  background-color: #999999; /* Darken button color on hover */
}
#randIcon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.searchBarContainer {
  max-width: 900px;
  gap: 10px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 768px) {
  .searchBarContainer {
    flex-wrap: wrap;
    width: 100vw;
    margin: 0;
  }
}
#searchBar {
  width: 100%;
  background-color: white;
  padding: 2px;
  border: none;
  outline: none;
  font-size: 18px;
}
.searchBarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid;
  background-color: white;
  width: 400px;
  padding: 8px 3px 8px 20px;
  border-radius: 20px;

  transition: border-color 0.6s ease 0s, background-color 0.2s ease 0s;
}
</style>
