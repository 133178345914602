const isDev = process.env.NODE_ENV === "development";

const apiUrl =
  location.protocol +
  "//" +
  location.hostname +
  (isDev ? ":8080" : ":443") +
  process.env.BASE_URL +
  "api";

export default {
  fetchUsdPrice() {
    return fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd"
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching USD price: ", reason);
      });
  },
  fetchGraph() {
    return fetch(apiUrl + "/graph/")
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching graph data: ", reason);
      });
  },
  fetchRandomNode() {
    return fetch(apiUrl + "/nodes/rand")
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching random node data: ", reason);
      });
  },
  fetchNodeByPubkey(pubkey) {
    return fetch(apiUrl + "/nodes/pub/" + pubkey)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching node data: ", reason);
      });
  },

  fetchNodeByAlias(alias) {
    return fetch(apiUrl + "/nodes/alias/" + alias)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching node data: ", reason);
      });
  },
  fetchNodeBasicHistoryByPubkey(pubkey) {
    return fetch(apiUrl + "/nodes/days/7/pub/" + pubkey)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching node data: ", reason);
      });
  },
  fetchNodeHistoryByPubkey(days, pubkey) {
    return fetch(apiUrl + "/nodes/days/" + days + "/pub/" + pubkey)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching node data: ", reason);
      });
  },
  fetchNodeEdgesByPubkey(pubkey) {
    return fetch(apiUrl + "/edges/pub/" + pubkey)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching node channel data: ", reason);
      });
  },
  fetchEdgesBySessionId(sessionId) {
    return fetch(apiUrl + "/edges/session/" + sessionId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching node channel data: ", reason);
      });
  },
  fetchNodeEdgesByPubkeys(pubkeys) {
    return fetch(apiUrl + "/edges/pubs/" + pubkeys)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching node channel data: ", reason);
      });
  },
  fetchNodePeersByPubkey(pubkey) {
    return fetch(apiUrl + "/peers/pub/" + pubkey)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching node peer data: ", reason);
      });
  },
  fetchEdgeByChanId(chanId) {
    return fetch(apiUrl + "/edges/chanid/" + chanId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching channel data: ", reason);
      });
  },
  fetchEdgeHistoryByChanId(days, chanId) {
    return fetch(apiUrl + "/edges/days/" + days + "/chanid/" + chanId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok");
      })
      .catch((reason) => {
        this.consoleLog("Error fetching node data: ", reason);
      });
  },
  consoleLog(message, reason) {
    if (isDev) {
      console.log(message, reason);
    }
  },
};
