// store.js
import { createStore } from "vuex";
import ApiService from "@/services/ApiService";

export default createStore({
  state: {
    networkLayerCount: 0,
    graphForceLayout: "On",
    graphForceLayoutOn: "On",
    graphForceLayoutOff: "Off",
    graphNeighborChan: "Hide",
    graphNeighborChanHide: "Hide",
    graphNeighborChanShow: "Show",
    nodeHistories: [],
    edgeHistories: [],
    peers: [],
    graphNodes: {},
    graphEdges: {},
    neighborEdges: [],
    graph: "",
    usdPrice: 0,
    // userAgent: navigator.userAgent,
    searchedNode: {
      pubkey: "",
      alias: "",
      capacity: "",
      channelCount: "",
      lastUpdate: "",
      degrCenRank: "",
      betwCenRank: "",
      closCenRank: "",
    },
    searchedEdge: {
      channelId: "",
      chanPoint: "",
      lastUpdate: "",
      node1Pub: "",
      node2Pub: "",
      node1Alias: "",
      node2Alias: "",
      node1Capacity: "",
      node2Capacity: "",
      capacity: "",
      node1TimeLockDelta: "",
      node1MinHtlc: "",
      node1FeeBaseMsat: "",
      node1FeeRateMilliMsat: "",
      node1Disabled: "",
      node1MaxHtlcMsat: "",
      node1LastUpdate: "",
      node2TimeLockDelta: "",
      node2MinHtlc: "",
      node2FeeBaseMsat: "",
      node2FeeRateMilliMsat: "",
      node2Disabled: "",
      node2MaxHtlcMsat: "",
      node2LastUpdate: "",
    },
    selectedEdge: {
      channelId: "",
      chanPoint: "",
      lastUpdate: "",
      node1Pub: "",
      node2Pub: "",
      node1Alias: "",
      node2Alias: "",
      node1Capacity: "",
      node2Capacity: "",
      capacity: "",
      node1TimeLockDelta: "",
      node1MinHtlc: "",
      node1FeeBaseMsat: "",
      node1FeeRateMilliMsat: "",
      node1Disabled: "",
      node1MaxHtlcMsat: "",
      node1LastUpdate: "",
      node2TimeLockDelta: "",
      node2MinHtlc: "",
      node2FeeBaseMsat: "",
      node2FeeRateMilliMsat: "",
      node2Disabled: "",
      node2MaxHtlcMsat: "",
      node2LastUpdate: "",
    },
    selectedEdgeKey: [],
    searchText: "",
    searchedPubkey: "",
    searchedAlias: "",
    searchedChanId: "",
    loading: false,
    shouldLoadPeerGraph: false,
    shouldLoadNeighbors: false,
    nodeHistoryDays: 30,
    nodeHistToggleOptions: [30, 60, 90],
    edgeHistoryDays: 7,
    edgeHistToggleOptions: [7, 30],
  },
  mutations: {
    SET_SHOULD_LOAD_EDGE_GRAPH(state) {
      state.shouldLoadPeerGraph =
        Array.isArray(state.peers) &&
        Object.keys(state.graphNodes).length > 0 &&
        Object.keys(state.graphEdges).length > 0 &&
        Object.keys(state.graphNodes).length <= 500;
      state.shouldLoadNeighbors =
        state.shouldLoadPeerGraph &
        (Object.keys(state.graphNodes).length < 100);
    },
    INCR_NETWORK_LAYER_COUNT(state) {
      // only allow 3 layers. growing too much could cause performance issues
      if (state.networkLayerCount < 3) {
        state.networkLayerCount += 1;
      }
    },
    SET_GRAPH_FORCE_LAYOUT(state) {
      state.graphForceLayout = state.graphForceLayoutOn;
    },
    RESET_GRAPH_FORCE_LAYOUT(state) {
      state.graphForceLayout = state.graphForceLayoutOff;
    },
    SET_GRAPH_NEIGHBOR_CHAN(state) {
      state.graphNeighborChan = state.graphNeighborChanShow;
    },
    RESET_GRAPH_NEIGHBOR_CHAN(state) {
      state.graphNeighborChan = state.graphNeighborChanHide;
    },
    SET_USD_PRICE(state, price) {
      state.usdPrice = price.bitcoin.usd;
    },
    SET_GRAPH(state, graph) {
      state.graph = graph;
    },
    SET_EDGES(state, newEdgeHistories) {
      state.edgeHistories = newEdgeHistories;
    },
    SET_GRAPH_NODES(state, newGraphNodes) {
      state.graphNodes = newGraphNodes;
    },
    SET_GRAPH_EDGES(state, newGraphEdges) {
      state.graphEdges = newGraphEdges;
    },
    SET_NEIGHBOR_EDGES(state, newNeighborEdges) {
      state.neighborEdges = newNeighborEdges;
    },
    SET_PEERS(state, newPeers) {
      state.peers = newPeers;
    },
    SET_SELECTED_EDGES(state, selectedEdge) {
      state.selectedEdge = selectedEdge;
    },
    SET_SELECTED_EDGE_KEY(state, selectedEdgeKey) {
      state.selectedEdgeKey = selectedEdgeKey;
    },
    SET_SEARCH_TEXT(state, searchText) {
      state.searchText = searchText;
    },
    SET_SEARCHED_PUBKEY(state, pubkey) {
      state.searchedPubkey = pubkey;
    },
    SET_SEARCHED_ALIAS(state, alias) {
      state.searchedAlias = alias;
    },
    SET_SEARCHED_CHANID(state, chanId) {
      state.searchedChanId = chanId;
    },
    SET_SEARCHED_NODE(state, searchedNode) {
      state.searchedNode = searchedNode;
    },
    SET_SEARCHED_EDGE(state, searchedEdge) {
      state.searchedEdge = searchedEdge;
    },
    SET_NODE_HISTORY(state, newNodeHistories) {
      state.nodeHistories = newNodeHistories;
    },
    SET_NODE_HISTORY_DAYS(state, days) {
      state.nodeHistoryDays = days;
    },
    SET_EDGE_HISTORY(state, newEdgeHistories) {
      state.edgeHistories = newEdgeHistories;
    },
    SET_EDGE_HISTORY_DAYS(state, days) {
      state.edgeHistoryDays = days;
    },
    RESET_SHOULD_LOAD_EDGE_GRAPH(state) {
      state.shouldLoadPeerGraph = false;
    },
    CLEAR_SEARCH_TEXT(state) {
      state.searchText = "";
    },
    CLEAR_SEARCHED_PUBKEY(state) {
      state.searchedPubkey = "";
    },
    CLEAR_SEARCHED_ALIAS(state) {
      state.searchedAlias = "";
    },
    CLEAR_SEARCHED_CHANID(state) {
      state.searchedChanId = "";
    },
    CLEAR_SEARCHED_NODE(state) {
      state.searchedNode = {
        pubkey: "",
        alias: "",
        capacity: "",
        channelCount: "",
        lastUpdate: "",
        degrCenRank: "",
        betwCenRank: "",
        closCenRank: "",
      };
    },
    CLEAR_SEARCHED_EDGE(state) {
      state.searchedEdge = {
        channelId: "",
        chanPoint: "",
        lastUpdate: "",
        node1Pub: "",
        node2Pub: "",
        node1Alias: "",
        node2Alias: "",
        node1Capacity: "",
        node2Capacity: "",
        capacity: "",
        node1TimeLockDelta: "",
        node1MinHtlc: "",
        node1FeeBaseMsat: "",
        node1FeeRateMilliMsat: "",
        node1Disabled: "",
        node1MaxHtlcMsat: "",
        node1LastUpdate: "",
        node2TimeLockDelta: "",
        node2MinHtlc: "",
        node2FeeBaseMsat: "",
        node2FeeRateMilliMsat: "",
        node2Disabled: "",
        node2MaxHtlcMsat: "",
        node2LastUpdate: "",
      };
    },
    CLEAR_NEIGHBOR_EDGES(state) {
      state.neighborEdges = [];
    },
    CLEAR_SELECTED_EDGES(state) {
      state.selectedEdge = [];
    },
    CLEAR_SELECTED_EDGE_KEY(state) {
      state.selectedEdgeKey = [];
    },
    CLEAR_SEARCHED_NODE_HISTORY(state) {
      state.nodeHistories = [];
    },
    CLEAR_SEARCHED_EDGE_HISTORY(state) {
      state.edgeHistories = [];
    },
    CLEAR_PEERS(state) {
      state.peers = [];
    },
    CLEAR_GRAPH_NODES(state) {
      state.graphNodes = {};
    },
    CLEAR_GRAPH_EDGES(state) {
      state.graphEdges = {};
    },
    RESET_NETWORK_LAYER_COUNT(state) {
      state.networkLayerCount = 0;
    },
    START_LOADING(state) {
      state.loading = true;
    },
    STOP_LOADING(state) {
      state.loading = false;
    },
  },
  actions: {
    incrNetworkLayerCount: (context) => {
      context.commit("INCR_NETWORK_LAYER_COUNT");
    },
    resetNetoworkLayerCount: (context) => {
      context.commit("RESET_NETWORK_LAYER_COUNT");
    },
    resetGraphForceLayout(context) {
      context.commit("RESET_GRAPH_FORCE_LAYOUT");
    },
    setGraphForceLayout(context) {
      context.commit("SET_GRAPH_FORCE_LAYOUT");
    },
    resetGraphNeighborChan(context) {
      context.commit("RESET_GRAPH_NEIGHBOR_CHAN");
    },
    setGraphNeighborChan(context) {
      context.commit("SET_GRAPH_NEIGHBOR_CHAN");
    },
    setGraphNodes(context, nodes) {
      context.commit("SET_GRAPH_NODES", nodes);
    },
    setShouldLoadPeerGraph(context) {
      context.commit("SET_SHOULD_LOAD_EDGE_GRAPH");
    },
    setGraphEdges(context, edges) {
      context.commit("SET_GRAPH_EDGES", edges);
    },
    setSearchText(context, text) {
      context.commit("SET_SEARCH_TEXT", text);
    },
    setSearchedNodePubkey(context, pubkey) {
      context.commit("SET_SEARCHED_PUBKEY", pubkey);
    },
    setSelectedEdgeChanId(context, chanId) {
      context.commit("SET_SELECTED_EDGE_KEY", chanId);
    },
    setSearchedNodeAlias(context, alias) {
      context.commit("SET_SEARCHED_ALIAS", alias);
    },
    setSearchedChanId(context, chanId) {
      context.commit("SET_SEARCHED_CHANID", chanId);
    },
    selectNodeHistoryDays(context, days) {
      context.commit("SET_NODE_HISTORY_DAYS", days);
    },
    selectEdgeHistoryDays(context, days) {
      context.commit("SET_EDGE_HISTORY_DAYS", days);
    },
    fetchGraph: (context) => {
      ApiService.fetchGraph()
        .then((json) => context.commit("SET_GRAPH", json))
        .catch((reason) => console.log("Error: " + reason));
    },
    fetchUsdPrice: (context) => {
      ApiService.fetchUsdPrice()
        .then((json) => context.commit("SET_USD_PRICE", json))
        .catch((reason) => console.log("Error: " + reason));
    },
    // is this needed?
    fetchEdges: (context) => {
      ApiService.fetchEdges()
        .then((json) => context.commit("SET_EDGES", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    clearNodeHistory(context) {
      context.commit("CLEAR_SEARCHED_NODE_HISTORY");
    },
    clearPeers(context) {
      context.commit("CLEAR_PEERS");
    },
    resetShouldLoadEdgeGraph: (context) => {
      context.commit("RESET_SHOULD_LOAD_EDGE_GRAPH");
    },
    clearSelectedEdges(context) {
      context.commit("CLEAR_SELECTED_EDGES");
    },
    clearNeighborEdges(context) {
      context.commit("CLEAR_NEIGHBOR_EDGES");
    },
    clearSelectedEdgeKey(context) {
      context.commit("CLEAR_SELECTED_EDGE_KEY");
    },
    clearNode(context) {
      context.commit("CLEAR_SEARCHED_NODE");
    },
    clearPubkey(context) {
      context.commit("CLEAR_SEARCHED_PUBKEY");
    },
    clearAlias(context) {
      context.commit("CLEAR_SEARCHED_ALIAS");
    },
    clearSearchedChanId(context) {
      context.commit("CLEAR_SEARCHED_CHANID");
    },
    fetchRandomNode: (context) => {
      context.commit("START_LOADING");
      ApiService.fetchRandomNode()
        .then((json) => context.commit("SET_SEARCHED_NODE", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    fetchNodeByPubkey: (context) => {
      context.commit("START_LOADING");
      ApiService.fetchNodeByPubkey(context.state.searchedPubkey)
        .then((json) => context.commit("SET_SEARCHED_NODE", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },

    fetchNodeByAlias: (context) => {
      context.commit("START_LOADING");
      ApiService.fetchNodeByAlias(context.state.searchedAlias)
        .then((json) => context.commit("SET_SEARCHED_NODE", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    fetchNodeBasicHistoryByPubkey: (context) => {
      context.commit("START_LOADING");
      ApiService.fetchNodeBasicHistoryByPubkey(
        context.state.searchedNode.pubkey
      )
        .then((json) => context.commit("SET_NODE_HISTORY", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    fetchNodeHistoryByPubkey: (context) => {
      context.commit("START_LOADING");
      ApiService.fetchNodeHistoryByPubkey(
        context.state.nodeHistoryDays,
        context.state.searchedNode.pubkey
      )
        .then((json) => context.commit("SET_NODE_HISTORY", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    fetchNodeEdgesByPubkey: (context) => {
      context.commit("START_LOADING");
      ApiService.fetchNodeEdgesByPubkey(context.state.searchedNode.pubkey)
        .then((json) => context.commit("SET_EDGES", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },

    fetchEdgesBySessionId: (context) => {
      context.commit("START_LOADING");
      const sessionId = context.state.peers[0].sessionId;

      ApiService.fetchEdgesBySessionId(sessionId)
        .then((json) => context.commit("SET_NEIGHBOR_EDGES", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    fetchNodeEdgesByPubkeys: (context) => {
      context.commit("START_LOADING");
      const peerPubs = context.state.peers
        .map((item) => item.peerPub)
        .join(",");
      ApiService.fetchNodeEdgesByPubkeys(peerPubs)
        .then((json) => context.commit("SET_NEIGHBOR_EDGES", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    fetchSelectedEdgeByChanId: (context) => {
      context.commit("START_LOADING");
      // update this if ever need to get multiple channels
      ApiService.fetchEdgeByChanId(context.state.selectedEdgeKey[0])
        .then((json) => context.commit("SET_SELECTED_EDGES", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    fetchSearchedEdgeByChanId: (context) => {
      context.commit("START_LOADING");
      ApiService.fetchEdgeByChanId(context.state.searchedChanId)
        .then((json) => context.commit("SET_SEARCHED_EDGE", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    fetchEdgeHistoryByChanId: (context) => {
      context.commit("START_LOADING");
      ApiService.fetchEdgeHistoryByChanId(
        context.state.edgeHistoryDays,
        context.state.searchedEdge.channelId
      )
        .then((json) => context.commit("SET_EDGE_HISTORY", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => context.commit("STOP_LOADING"));
    },
    fetchNodePeersByPubkey: (context) => {
      context.commit("START_LOADING");
      ApiService.fetchNodePeersByPubkey(context.state.searchedNode.pubkey)
        .then((json) => context.commit("SET_PEERS", json))
        .catch((reason) => console.log("Error: " + reason))
        .finally(() => {
          context.commit("STOP_LOADING");
        });
    },
    clearAllSearchResults(context) {
      const clearPromises = [
        context.commit("CLEAR_SEARCH_TEXT"),
        context.commit("CLEAR_SEARCHED_PUBKEY"),
        context.commit("CLEAR_SEARCHED_ALIAS"),
        context.commit("CLEAR_SEARCHED_CHANID"),
        context.commit("CLEAR_SEARCHED_NODE"),
        context.commit("CLEAR_SEARCHED_NODE_HISTORY"),
        context.commit("CLEAR_SEARCHED_EDGE"),
        context.commit("CLEAR_SEARCHED_EDGE_HISTORY"),
        context.commit("RESET_SHOULD_LOAD_EDGE_GRAPH"),
        context.commit("CLEAR_GRAPH_EDGES"),
        context.commit("CLEAR_GRAPH_NODES"),
        context.commit("CLEAR_PEERS"),
        context.commit("CLEAR_SELECTED_EDGES"),
        context.commit("CLEAR_SELECTED_EDGE_KEY"),
        context.commit("CLEAR_NEIGHBOR_EDGES"),
        context.commit("RESET_NETWORK_LAYER_COUNT"),
        context.commit("RESET_GRAPH_NEIGHBOR_CHAN"),
        context.commit("SET_GRAPH_FORCE_LAYOUT"),
        // Add more "CLEAR_" mutations if needed
      ];

      // Return a promise that resolves when all "CLEAR_" mutations are done
      return Promise.all(clearPromises);
    },
  },
});
