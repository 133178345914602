<template>
  <div class="nodeGrid">
    <basic-field
      :label="this.capacityLabel"
      :value="this.$store.state.searchedNode.capacity"
    ></basic-field>
    <div
      class="basicInfoFieldContainer"
      v-if="this.$store.state.searchedNode.channelCount"
    >
      <div class="basicInfoLabel">Number of Channels</div>
      <div class="basicInfoValue">
        {{ formatChannelCount }}
        <div class="basicInfoUnit">channels</div>
      </div>
    </div>
    <basic-field
      :label="this.lastUpdateLabel"
      :value="this.$store.state.searchedNode.lastUpdate"
    ></basic-field>

    <basic-field
      :label="this.degrCenLabel"
      :title="this.degrCenTitle"
      :value="this.$store.state.searchedNode.degrCenRank"
    ></basic-field>

    <basic-field
      :label="this.betwCenLabel"
      :title="this.betwCenTitle"
      :value="this.$store.state.searchedNode.betwCenRank"
    ></basic-field>

    <basic-field
      :label="this.closCenLabel"
      :title="this.closCenTitle"
      :value="this.$store.state.searchedNode.closCenRank"
    ></basic-field>
  </div>
</template>

<script>
import BasicField from "@/components/BasicField";

export default {
  name: "NodeBasicInfo",
  components: { BasicField },

  inject: [
    "getNumberFormattedCommas",
    "getTimeSinceLastUpdate",
    "convertSatsToUsd",
  ],
  data() {
    return {
      capacityLabel: "Capacity",
      lastUpdateLabel: "Last Update",
      degrCenLabel: "Degree Centrality",
      degrCenTitle:
        "Degree centrality measures the number of connections a node has. If the goal is to maximize transaction volume, degree centrality may be a good metric to use.",
      betwCenLabel: "Betweennes Centrality",
      betwCenTitle:
        "Betweenness centrality measures the node's influence on the flow of transactions in the network. If the goal is to ensure robustness and resilience in the face of potential disruptions, betweenness centrality may be good to optimize for.",
      closCenLabel: "Closeness Centrality",
      closCenTitle:
        "Closeness centrality measures how quickly a node can reach other nodes in the network. If the goal is to optimize transaction speed and efficiency, closeness centrality may be the most appropriate metric to use.",
    };
  },
  computed: {
    getUsdCapacity() {
      return this.convertSatsToUsd(this.$store.state.searchedNode.capacity);
    },
    formatChannelCount() {
      return this.getNumberFormattedCommas(
        this.$store.state.searchedNode.channelCount
      );
    },
  },
};
</script>

<style scoped>
.nodeGrid {
  margin-top: 16px;
  padding: 0 0 16px;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
}
.basicInfoFieldContainer {
  margin: 0 0 16px;
}
.basicInfoLabel {
  display: flex;
  font-size: 12px;
  align-items: flex-end;
}
.basicInfoValue {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  align-items: flex-end;
}
.basicInfoUnit {
  font-size: 12px;
  margin: 0 0 2px 2px;
}
</style>
