<template>
  <div class="logo">
    <router-link to="/">
      <img src="../assets/quiche_logo.png" alt="Quiche" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "LogoImage",
  inject: ["isMobile"],
  computed: {
    thisIsMobile() {
      return this.isMobile();
    },
  },
};
</script>

<style scoped>
.logo {
  margin: 20px;
}
</style>
