<template>
  <div
    class="basicFieldContainer"
    v-if="!thisIsNumber || (thisIsNumber && getNumberValue(value) > 0)"
  >
    <div class="basicInfoLabel">{{ label }}</div>
    <div
      class="basicInfoValue"
      v-if="isMilliSatField"
      :title="convSatsToUsd(convMillisatToSat(value))"
    >
      {{ getNumberFormattedCommas(convMillisatToSat(value)) }}
      <div class="basicInfoUnit">sats</div>
    </div>
    <div
      class="basicInfoValue"
      v-else-if="isSatField"
      :title="convSatsToUsd(value)"
    >
      {{ getNumberFormattedCommas(value) }}
      <div class="basicInfoUnit">sats</div>
    </div>
    <div class="basicInfoValue" v-else-if="isPubkeyField">
      <a
        class="basicInfoValueLink"
        v-if="
          !thisIsNodeRoute || (thisIsNodeRoute && !isSearchedNodePubkeyValue)
        "
        @click="goToNodeViewRoute"
      >
        {{ formatPubkey(value) }}</a
      >
      <div v-else>
        {{ formatPubkey(value) }}
      </div>
      <copy-icon :content="value" />
    </div>
    <div class="basicInfoValue" v-else-if="isDateField">
      {{ getTimeSinceLastUpdate(value) }}
    </div>
    <div class="basicInfoValue" v-else-if="isCentralityField" :title="title">
      {{ getNumberFormattedCommas(value) }}
      <div class="basicInfoOrdinal">
        {{ getOrdinalIndicator(value) }}
      </div>
    </div>
    <div
      class="basicInfoValueLink"
      v-else-if="thisIsNumber && isChanIdField && !thisIsChannelRoute"
      @click="goToEdgeViewRoute"
    >
      <a>{{ value }}</a>
    </div>
    <div class="basicInfoValue" v-else>
      {{ value }}
    </div>
  </div>
</template>

<script>
import CopyIcon from "./CopyIcon.vue";

export default {
  name: "BasicField",
  components: { CopyIcon },
  props: ["label", "title", "value"],
  inject: [
    "getNumberFormattedCommas",
    "getTimeSinceLastUpdate",
    "convertMillisatToSat",
    "shortenPubkey",
    "convertSatsToUsd",
    "isNumber",
    "isNodeRoute",
    "isChannelRoute",
  ],
  data() {
    return {
      chanIdLabel: "Channel ID",
    };
  },
  computed: {
    thisIsNodeRoute() {
      return !!this.isNodeRoute();
    },
    thisIsChannelRoute() {
      return !!this.isChannelRoute();
    },
    thisIsNumber() {
      return this.isNumber(this.value);
    },
    isMilliSatField() {
      if (this.label) {
        const lowerLabel = this.label.toLowerCase();
        return lowerLabel.includes("fee") || lowerLabel.includes("htlc");
      } else {
        return false;
      }
    },
    isSatField() {
      if (this.label) {
        const lowerName = this.label.toLowerCase();
        return lowerName.includes("capacity");
      } else {
        return false;
      }
    },
    isChanIdField() {
      if (this.label) {
        const lowerName = this.label.toLowerCase();
        return lowerName.includes("channel") && lowerName.includes("id");
      } else {
        return false;
      }
    },
    isPubkeyField() {
      if (this.label) {
        const lowerName = this.label.toLowerCase();
        return lowerName.includes("pub");
      } else {
        return false;
      }
    },
    isSearchedNodePubkeyValue() {
      if (this.value) {
        return this.value === this.$store.state.searchedNode.pubkey;
      } else {
        return false;
      }
    },
    isDateField() {
      if (this.label) {
        const lowerName = this.label.toLowerCase();
        return lowerName.includes("last update");
      } else {
        return false;
      }
    },
    isCentralityField() {
      if (this.label) {
        const lowerName = this.label.toLowerCase();
        return lowerName.includes("centrality");
      } else {
        return false;
      }
    },
  },
  methods: {
    getNumberValue(val) {
      return Number(val);
    },
    convSatsToUsd(sats) {
      return this.convertSatsToUsd(sats);
    },
    convMillisatToSat(m) {
      return this.convertMillisatToSat(m);
    },
    formatPubkey(pubkey) {
      if (pubkey) {
        return this.shortenPubkey(pubkey);
      }
      return pubkey;
    },
    getOrdinalIndicator(number) {
      const lastDigit = number % 10;
      const secondLastDigit = Math.floor(number / 10) % 10;
      return secondLastDigit === 1 || lastDigit === 0 || lastDigit > 3
        ? "th"
        : lastDigit === 1
        ? "st"
        : lastDigit === 2
        ? "nd"
        : "rd";
    },
    goToEdgeViewRoute() {
      this.$router.push({
        name: "channel",
        params: { chanId: this.value },
      });
    },
    goToNodeViewRoute() {
      this.$router.push({
        name: "node",
        params: { pubkey: this.value },
      });
    },
  },
};
</script>

<style scoped>
.basicFieldContainer {
  margin: 0;
}
.basicInfoLabel {
  display: flex;
  font-size: 12px;
  align-items: flex-start;
}
.basicInfoValue {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  flex-wrap: wrap;
  align-items: flex-end;
  word-wrap: break-word;
}
.basicInfoUnit {
  font-size: 12px;
  margin: 0 0 2px 2px;
}
.basicInfoValueLink {
  font-weight: bold;
  font-size: 18px;

  text-decoration: underline;
  transition: color 0.2s;
}
.basicInfoValueLink:hover {
  color: #00bfff;
  cursor: pointer;
}
</style>
