<template>
  <span v-if="content">
    <span v-if="!copied" @click="copyToClipboard" class="copy-icon">
      <img src="../assets/icon-copy.png" alt="Copy" />
    </span>
    <span v-else class="checkmark-icon">
      <img src="../assets/icon-checkmark.png" alt="Check" />
    </span>
  </span>
</template>

<script>
export default {
  name: "CopyIcon",

  data() {
    return {
      copied: false, // State variable to track if the content is copied
    };
  },
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  methods: {
    copyToClipboard() {
      const el = document.createElement("textarea");
      el.value = this.content;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      // Show the checkmark for 2 seconds and then hide it
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
};
</script>

<style scoped>
.copy-icon {
  cursor: pointer;
  margin-left: 8px;
}

.checkmark-icon {
  margin-left: 8px;
}
</style>
