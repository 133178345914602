<template>
  <div class="body">
    <div class="searchResultContainer" v-if="this.thisNodeExists">
      <div class="roundedContainer">
        <div id="nodeHeadingContainer">
          <h1 class="nodeHeading" v-if="this.$store.state.searchedNode.alias">
            {{ this.$store.state.searchedNode.alias }}
          </h1>
          <h1 class="nodeHeading" v-else>
            {{ formatPubkey(this.$store.state.searchedNode.pubkey) }}
            <copy-icon :content="this.$store.state.searchedNode.pubkey" />
          </h1>
          <div id="pubkeyText">
            {{ formatPubkey(this.$store.state.searchedNode.pubkey) }}
            <copy-icon :content="this.$store.state.searchedNode.pubkey" />
          </div>
        </div>
        <node-basic-info />
      </div>
      <div
        class="searchResultContainer"
        v-if="
          !thisNoSearchText &&
          !thisSearchButNoResult &&
          !searchButNoHistoryResult
        "
      >
        <div
          class="roundedContainer"
          :class="{ lastComponent: !this.$store.state.shouldLoadPeerGraph }"
        >
          <toggle-bar
            :options="this.$store.state.nodeHistToggleOptions"
            :label="this.toggleBarLabel"
            :default-val="this.getNodeHistoryOptionDaysDefault"
            @option-clicked="thisSetNodeHistoryDaysReloadGraphs"
          />
          <div class="chartRow">
            <node-hist-chart
              v-if="this.nodeHistGraphLabels.length > 0"
              ref="nodeHistChart"
              :chart-labels="this.nodeHistGraphLabels"
              :chart-data="this.nodeHistGraphData"
              :chart-options="this.chartOptions"
              :line-color="blue"
            ></node-hist-chart>
          </div>
        </div>
      </div>
      <div
        class="searchResultContainer"
        v-if="
          !thisNoSearchText &&
          !thisSearchButNoResult &&
          this.$store.state.shouldLoadPeerGraph
        "
      >
        <div
          class="roundedContainer"
          :class="{ lastComponent: isLastComponent }"
        >
          <edge-graph-section :edge-data="this.thisEdgeData" />
        </div>
      </div>
    </div>
    <div
      v-else-if="
        thisSearchButNoResult && !thisNoSearchText && !this.$store.state.loading
      "
    >
      <h1 id="notFoundHeader" class="searchResultContainer">Node not found</h1>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import CopyIcon from "./CopyIcon.vue";
import NodeHistChart from "./NodeHistChart.vue";
import EdgeGraphSection from "./EdgeGraphSection.vue";
import NodeBasicInfo from "./NodeBasicInfo.vue";
import ToggleBar from "@/components/ToggleBar";

export default {
  name: "NodeDetails",
  components: {
    ToggleBar,
    NodeBasicInfo,
    EdgeGraphSection,
    NodeHistChart,
    CopyIcon,
  },
  inject: [
    "shortenPubkey",
    "noSearchText",
    "searchButNoResult",
    "getNumberFormattedCommas",
    "convertPeersToDisplayChannels",
    "convertEdgesToDisplayChannels",
    "calculateEdgeWidth",
    "calculateNodeRadius",
    "setNodeHistoryDaysReloadGraphs",
    "setNodeHistoryDays",
    "searchedNodeExists",
    "nodeHistFields",
  ],
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      blue: "#00BFFF",
      green: "#CBFF00",
    };
  },
  watch: {
    "$store.state.nodeHistories": {
      handler(newNodes) {
        if (newNodes && newNodes.length > 0) {
          // The 'nodeHistories' data has changed, so re-render the line charts
          this.$nextTick(() => {
            // Make sure the refs are defined in your template
            if (this.$refs.nodeHistChart) {
              this.$refs.nodeHistChart.renderChart();
            }
          });
        }
      },
      deep: true,
    },
    "$store.state.peers": {
      handler(newValue) {
        // Check if searchedNode is defined and has a pubkey property
        if (newValue && newValue[0] && newValue[0].peerPub) {
          this.thisConvertPeersToDisplayChannels(this.$store.state.peers);
          this.$store.dispatch("setShouldLoadPeerGraph");
        }
      },
      deep: true,
    },
  },
  computed: {
    toggleBarLabel() {
      return "Last " + this.$store.state.nodeHistoryDays + " Updates";
    },
    thisEdgeData() {
      return this.$store.state.selectedEdge;
    },
    thisNodeExists() {
      return this.searchedNodeExists();
    },
    thisNodeHistFields() {
      return this.nodeHistFields();
    },
    thisNoSearchText() {
      return this.noSearchText();
    },
    thisSearchButNoResult() {
      return this.searchButNoResult();
    },
    isLastComponent() {
      return true;
    },
    getNodeHistoryOptionDaysDefault() {
      let defaultDays = 7;
      if (!this.$store.state.nodeHistoryDays) {
        return defaultDays;
      }
      return this.$store.state.nodeHistoryDays;
    },
    searchButNoHistoryResult() {
      let pubKeyNotBlank, aliasNotBlank, nodeInfoNotBlank, nodeHistoryBlank;
      pubKeyNotBlank = !!this.$store.state.searchedPubkey;
      aliasNotBlank = !!this.$store.state.searchedAlias;
      nodeInfoNotBlank = this.$store.state.searchedNode !== undefined;
      nodeHistoryBlank =
        this.$store.state.nodeHistories === undefined ||
        this.$store.state.nodeHistories.length <= 0;
      return (
        (pubKeyNotBlank || aliasNotBlank) &&
        nodeInfoNotBlank &&
        nodeHistoryBlank
      );
    },
    nodeHistGraphLabels() {
      return this.$store.state.nodeHistories.map(
        (nodeHist) => nodeHist.dateTaken
      );
    },
    nodeHistFieldsWData() {
      let result = [];
      const nodeHists = this.$store.state.nodeHistories;
      const fields = this.thisNodeHistFields;
      nodeHists.map((e) => {
        fields.map((f) => {
          if (e[f.name]) {
            result.push(f.name);
          }
        });
      });
      return [...new Set(result)];
    },
    nodeHistGraphData() {
      let result = [];
      const fieldsWData = this.nodeHistFieldsWData;
      const allFields = this.thisNodeHistFields;
      const nodeHists = this.$store.state.nodeHistories;
      const fields = allFields.filter((f) => fieldsWData.includes(f.name));

      fields.map((f, i) => {
        const thisLabel = f.label;
        const isSat = thisLabel.toLowerCase() === "capacity";

        result.push({
          yAxisID: isSat ? "ySats" : "y",
          label: thisLabel,
          data: nodeHists.map((nh) => {
            return parseInt(nh[f.name], 10);
          }),
          backgroundColor: "rgba(54,73,93,.5)",
          borderWidth: 3,
          borderColor: i % 2 === 0 ? this.blue : this.green,
          fill: false,
          spanGaps: true,
        });
      });
      return result;
    },
  },
  methods: {
    formatPubkey(pubkey) {
      if (pubkey) {
        return this.shortenPubkey(pubkey);
      }
      return pubkey;
    },
    thisSetNodeHistoryDaysReloadGraphs(days) {
      this.setNodeHistoryDaysReloadGraphs(days);
    },
    thisConvertPeersToDisplayChannels(peers) {
      this.convertPeersToDisplayChannels(peers);
    },
    thisConvertEdgesToDisplayChannels(edges) {
      this.convertEdgesToDisplayChannels(edges);
    },
  },
};
</script>
<style scoped>
.roundedContainer {
  background-color: rgba(6, 8, 28, 0.9);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nodeHeading {
  font-size: 28px;
  font-weight: bolder;
  margin: 0;
  display: flex;
  align-items: flex-start;
}

#pubkeyText {
  font-size: 14px;
  display: flex;
  align-items: flex-start;
}
#notFoundHeader {
  font-size: 28px;
  font-weight: bolder;
  margin: 0;
}
#nodeHeadingContainer {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.searchResultContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  padding-top: 25px;
}
.lastComponent {
  margin-bottom: 100px;
}
.chartRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0 25px 0;
}
.body {
  display: flex;
  color: white;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 320px;
  min-height: 60vh;
}
</style>
