<template>
  <div>
    <h2>404</h2>
    <h3>Page not found</h3>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped></style>
