<template>
  <div
    :class="{
      graphContainer: !this.thisIsFullScreen,
      graphContainerFullScreen:
        this.thisIsFullScreen & !this.selectedEdgesExist,
      graphContainerFullScreenEdgeInfoVisible:
        this.thisIsFullScreen & this.selectedEdgesExist,
    }"
  >
    <v-network-graph
      class="graph"
      :nodes="graphNodes"
      :edges="graphEdges"
      :configs="configs"
      v-model:selectedEdges="this.$store.state.selectedEdgeKey"
    >
      <template #edge-label="{ edge, hovered, selected, ...slotProps }">
        <v-edge-label
          v-if="hovered || selected"
          :text="edge.label"
          :vertical-align="vAlign"
          v-bind="slotProps"
        /> </template
    ></v-network-graph>
  </div>
</template>

<script>
import { defineConfigs } from "v-network-graph";
import * as vNG from "v-network-graph";
import { ForceLayout } from "v-network-graph/lib/force-layout";

export default {
  name: "EdgeGraphDiagram",
  props: ["graphNodes", "graphEdges"],
  inject: ["isFullScreenGraph", "noSearchText", "searchButNoResult"],
  data() {
    return {
      vAlign: "above",
      layouts: {},
      configs: {
        node: {
          selectable: false,
          hoverable: true,

          normal: {
            radius: 16,
            color: "#6013ad",
          },
          hover: {
            color: "#430d78",
          },
          label: {
            fontSize: 11,
            color: "#ffffff",
            direction: "center",
          },
        },
        edge: {
          margin: 12,
          selectable: true,
          hoverable: true,
          label: {
            fontFamily: undefined,
            fontSize: 8,
            color: "#ffffff",
            margin: 4,
            background: {
              visible: false,
            },
          },
          normal: {
            width: 3,
            color: "#58139c",
            linecap: "round",
          },
          selected: {
            dasharray: "0",
            linecap: "round",
          },
          hover: {
            color: "#3a0d66",
            fontSize: 30,
            linecap: "round",
          },
          zOrder: {
            enabled: true,
            zIndex: (n) => n.zIndex,
            bringToFrontOnHover: true,
            bringToFrontOnSelected: true,
          },
        },
      },
    };
  },
  watch: {
    "$store.state.selectedEdgeKey": {
      handler(newValue) {
        // Check if selectedEdgeKey is defined
        if (
          newValue &&
          Array.isArray(newValue) &&
          Object.keys(newValue).length > 0
        ) {
          // Call your Vuex store method here
          this.$store.dispatch("fetchSelectedEdgeByChanId");
        } else {
          this.$store.dispatch("clearSelectedEdges");
        }
      },
      deep: true, // Watch for changes deeply in the object
    },
    "$store.state.networkLayerCount": {
      handler(newValue) {
        if (newValue) {
          this.setConfigs();
        }
      },
      deep: true, // Watch for changes deeply in the object
    },
    "$store.state.graphForceLayout": {
      handler(newValue) {
        if (newValue) {
          this.setConfigs();
        }
      },
      deep: true, // Watch for changes deeply in the object
    },
  },
  computed: {
    thisNoSearchText() {
      return this.noSearchText();
    },
    thisSearchButNoResult() {
      return this.searchButNoResult();
    },
    thisIsFullScreen() {
      // return this.isFullScreenGraph();

      const result =
        !this.thisNoSearchText &&
        !this.thisSearchButNoResult &&
        this.$store.state.searchedNode &&
        this.$store.state.searchedNode.pubkey &&
        this.$store.state.searchedNode.pubkey.trim() !== "" &&
        this.$route.path ===
          "/node/full/" + this.$store.state.searchedNode.pubkey;
      return result;
    },
    selectedEdgesExist() {
      return (
        !!this.$store.state.selectedEdge &&
        !!this.$store.state.selectedEdge.channelId &&
        this.$store.state.selectedEdge.channelId !== ""
      );
    },
    isNeighborsExist() {
      return this.$store.state.networkLayerCount > 0;
    },
  },
  methods: {
    setConfigs() {
      if (
        this.$store.state.graphForceLayout ===
        this.$store.state.graphForceLayoutOff
      ) {
        this.configs = defineConfigs({
          view: {
            layoutHandler: new vNG.SimpleLayout(),
          },
          edge: {
            normal: {
              width: (edge) => edge.width || 3,
              color: (edge) => edge.color || "#58139c",
            },
          },
          node: {
            normal: {
              radius: (node) => node.radius || 6,
            },
          },
        });
      } else if (
        this.$store.state.graphForceLayout ===
        this.$store.state.graphForceLayoutOn
      ) {
        this.configs = defineConfigs({
          view: {
            layoutHandler: new ForceLayout({
              positionFixedByDrag: false,
              positionFixedByClickWithAltKey: true,
              createSimulation: (d3, nodes, edges) => {
                // d3-force parameters
                const forceLink = d3.forceLink(edges).id((d) => d.id);
                const edgeStrength = this.isNeighborsExist ? 0.08 : 0.5;
                // Set the initial position of the first node to the center
                nodes[0].x = this.$el.offsetWidth / 0.9;
                nodes[0].y = this.$el.offsetHeight;

                return d3
                  .forceSimulation(nodes)
                  .force("edge", forceLink.distance(40).strength(edgeStrength))
                  .force("charge", d3.forceManyBody().strength(-800))
                  .force(
                    "center",
                    d3
                      .forceCenter(
                        this.$el.offsetWidth / 2,
                        this.$el.offsetHeight / 2
                      )
                      .strength(0.05)
                  )
                  .alphaMin(0.001);
              },
            }),
          },
          edge: {
            normal: {
              width: (edge) => edge.width || 3,
              color: (edge) => edge.color || "#58139c",
            },
          },
          node: {
            normal: {
              radius: (node) => node.radius || 6,
            },
          },
        });
      }
    },
  },
  mounted() {
    this.setConfigs();
  },
};
</script>

<style scoped>
.graph {
  cursor: grab;
}
.graph:active {
  cursor: grabbing;
}
.graphContainer {
  margin: 0;
  width: 100%;
  height: 100%;
}
.graphContainerFullScreen {
  position: fixed;
  left: 50px;
  right: 50px;
  bottom: 0;
  top: 80px;
  margin: 0;
}
.graphContainerFullScreenEdgeInfoVisible {
  margin: 0;
  position: fixed;
  left: 50px;
  right: 50px;
  top: 80px;
}
</style>
