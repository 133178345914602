<template>
  <header>
    <div class="headerWrapper">
      <div id="leftHeader" v-if="!this.thisIsMobile">
        <logo-image />
      </div>
      <div class="midHeader" :class="{ midHeaderMobile: this.thisIsMobile }">
        <div class="logoTitle">
          <logo-image v-if="this.thisIsMobile" />
          <h1>Mr. Quiche</h1>
        </div>

        <search-bar
          v-if="!this.thisNoSearchText && !this.thisSearchButNoResult"
        ></search-bar>
      </div>
      <div id="rightHeader" v-if="!this.thisIsMobile">
        <div class="grid-container" v-if="this.$store.state.graph">
          <!-- Upper Left Quadrant: Nodes -->
          <div class="quadrant" id="upperLeftQuadrant">
            <div class="quadrantLabel">Nodes</div>
            <div class="quadrantValue">
              {{ formatNodeCount }}
            </div>
          </div>

          <!-- Upper Right Quadrant: Communities -->
          <div class="quadrant" id="upperRightQuadrant">
            <div class="quadrantLabel">Communities</div>
            <div class="quadrantValue">
              {{ this.$store.state.graph.numComms }}
            </div>
          </div>

          <!-- Lower Left Quadrant: Channels -->
          <div class="quadrant" id="lowerLeftQuadrant">
            <div class="quadrantLabel">Channels</div>
            <div class="quadrantValue">
              {{ formatChannelCount }}
            </div>
          </div>

          <!-- Lower Right Quadrant: Density -->
          <div class="quadrant" id="lowerRightQuadrant">
            <div class="quadrantLabel">Density</div>
            <div class="quadrantValue">{{ formatDensity }}</div>
          </div>
        </div>
        <div v-else></div>
      </div>
    </div>
  </header>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import LogoImage from "@/components/LogoImage.vue";

export default {
  name: "AppHeader",
  components: { LogoImage, SearchBar },

  inject: [
    "getNumberFormattedCommas",
    "noSearchText",
    "searchButNoResult",
    "isMobile",
  ],
  computed: {
    thisIsMobile() {
      return this.isMobile();
    },
    thisNoSearchText() {
      return this.noSearchText();
    },
    thisSearchButNoResult() {
      return this.searchButNoResult();
    },
    formatChannelCount() {
      return this.getNumberFormattedCommas(this.$store.state.graph.numEdges);
    },
    formatNodeCount() {
      return this.getNumberFormattedCommas(this.$store.state.graph.numNodes);
    },
    formatDensity() {
      return this.$store.state.graph.density.substring(0, 8);
    },
  },
};
</script>

<style scoped>
header {
  background-color: rgba(6, 8, 28, 0.9);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  right: 0;
  padding: 0 0 20px 0;
  min-width: max-content;
}
header h1 {
  text-align: center;
  font-size: 36px;
}

.headerWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.logoTitle {
  display: flex;
  justify-content: center;

  flex-direction: row;
  align-items: center;
}
@media (max-width: 768px) {
  header {
    justify-content: space-between;
  }
  .headerWrapper {
    display: flex;
    justify-content: space-between;
  }
  .midHeader {
    width: 100%;
  }
}

#leftHeader {
  width: 150px;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  left: 0;
}

.midHeader {
  padding-top: 20px;
  width: 35%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.midHeaderMobile {
  width: 100%;
}

#rightHeader {
  width: 200px;
  padding: 30px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  right: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
}

.quadrant {
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 0 10px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.quadrantLabel {
  font-size: 14px;
  font-weight: bold;
}

.quadrantValue {
  font-size: 12px;
}
</style>
