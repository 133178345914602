<template>
  <div>
    <div class="searchResultContainer" v-if="this.thisEdgeExists">
      <div class="roundedContainer">
        <edge-basic-info
          :class="{ edgeBasicInfoFullScreen: thisIsFullScreen }"
          :edge-data="this.thisEdgeData"
          ref="edgeBasicInfoRef"
        />
      </div>
    </div>
    <div v-else-if="!this.thisEdgeExists && !this.$store.state.loading">
      <h1 id="notFoundHeader" class="searchResultContainer">
        Channel not found
      </h1>
    </div>
    <div v-else></div>
    <div class="searchResultContainer" v-if="this.thisEdgeExists">
      <div class="roundedContainer" :class="{ lastComponent: isLastComponent }">
        <toggle-bar
          class="toggleBar"
          :options="this.$store.state.edgeHistToggleOptions"
          :label="this.toggleBarLabel"
          :default-val="this.getEdgeHistoryOptionDaysDefault"
          @option-clicked="thisSetEdgeHistoryDaysReloadGraphs"
        />
        <edge-chart
          v-if="this.edgeGraphLabels.length > 0"
          ref="edgeFeeChart"
          :chart-data="this.edgeNodeGraphData"
          :chart-labels="this.edgeGraphLabels"
          :chart-options="this.chartOptions"
          :line-color="blue"
        ></edge-chart>
      </div>
    </div>
  </div>
</template>

<script>
import EdgeBasicInfo from "@/components/EdgeBasicInfo.vue";
import EdgeChart from "@/components/EdgeChart";
import ToggleBar from "@/components/ToggleBar.vue";

export default {
  name: "EdgeView",
  components: {
    EdgeBasicInfo,
    EdgeChart,
    ToggleBar,
  },
  inject: [
    "noSearchText",
    "searchButNoResult",
    "isFullScreenGraph",
    "setEdgeHistoryDaysReloadGraphs",
    "setEdgeHistoryDays",
    "edgeFields",
    "searchedEdgeExists",
  ],
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      blue: "rgb(0, 96, 128)",
      green: "rgb(102, 128, 0)",
      node1AliasFieldName: "node1Alias",
      node2AliasFieldName: "node2Alias",
    };
  },
  watch: {
    "$store.state.edgeHistories": {
      handler(newEdges) {
        if (newEdges && newEdges.length > 0) {
          // The 'edgeHistories' data has changed, so re-render the line charts
          this.$nextTick(() => {
            // Make sure the refs are defined in your template
            if (this.$refs.edgeFeeChart) {
              this.$refs.edgeFeeChart.renderChart();
            }
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    isLastComponent() {
      return true;
    },
    toggleBarLabel() {
      return "Last " + this.$store.state.edgeHistoryDays + " Updates";
    },
    thisEdgeFields() {
      return this.edgeFields();
    },
    thisEdgeExists() {
      return this.searchedEdgeExists();
    },
    edgeNode1Alias() {
      return this.$store.state.searchedEdge[this.node1AliasFieldName];
    },
    edgeNode2Alias() {
      return this.$store.state.searchedEdge[this.node2AliasFieldName];
    },
    getEdgeHistoryOptionDaysDefault() {
      let defaultDays = 7;
      if (!this.$store.state.edgeHistoryDays) {
        return defaultDays;
      }
      return this.$store.state.edgeHistoryDays;
    },
    edgeFieldsWData() {
      let result = [];
      const edgeHists = this.$store.state.edgeHistories;
      const fields = this.thisEdgeFields;
      edgeHists.map((e) => {
        fields.map((f) => {
          if (e[f.name]) {
            result.push(f.name);
          }
        });
      });
      return [...new Set(result)];
    },
    edgeGraphLabels() {
      return this.$store.state.edgeHistories.map(
        (edgeHist) => edgeHist.dateTaken
      );
    },
    edgeNodeGraphData() {
      let result = [];
      const fieldsWData = this.edgeFieldsWData;
      const allFields = this.thisEdgeFields;
      const edgeHists = this.$store.state.edgeHistories;
      const fields = allFields.filter((f) => fieldsWData.includes(f.name));
      const numColors = this.numColorsInPalette(fieldsWData) / 2;
      const blueColors = this.generatePalette(numColors, this.blue);
      const greenColors = this.generatePalette(numColors, this.green);

      fields.map((f, i) => {
        let thisLabel = f.label;
        if (thisLabel.startsWith("Peer ")) {
          thisLabel = thisLabel.slice(5);
        }
        const thisName = f.name;
        const isOne = thisName.includes("1");
        const isSat = thisLabel.toLowerCase().includes("htlc");

        if (isOne) {
          thisLabel = `${this.edgeNode1Alias} ${thisLabel}`;
        } else if (thisName.includes("2")) {
          thisLabel = `${this.edgeNode2Alias} ${thisLabel}`;
        }

        result.push({
          yAxisID: isSat ? "ySats" : "y",
          label: thisLabel,
          data: edgeHists.map((eh) => {
            return parseInt(eh[f.name], 10);
          }),
          backgroundColor: "rgba(54,73,93,.5)",
          borderWidth: 3,
          borderColor: isOne
            ? blueColors[i]
            : greenColors[this.convertNumber(i)],
          fill: false,
          spanGaps: true,
        });
      });
      return result;
    },
    thisNoSearchText() {
      return this.noSearchText();
    },
    thisSearchButNoResult() {
      return this.searchButNoResult();
    },
    thisIsFullScreen() {
      return this.isFullScreenGraph();
    },
    thisEdgeData() {
      return this.$store.state.searchedEdge;
    },
  },
  methods: {
    numColorsInPalette(array) {
      if (Array.isArray(array) && array.length > 0) {
        return array.length - 1;
      }
      return 0;
    },
    generatePalette(numColors, baseColor) {
      const rgbArr = this.parseRGBString(baseColor);
      const lastStep = 100 / (numColors - 1);
      const step = lastStep * 0.01 * 255; // Convert percentage to step value
      let colorPalette = [];
      for (let i = 0; i < numColors; i++) {
        const r = Math.min(255, Math.round(rgbArr[0] + i * step));
        const g = Math.min(255, Math.round(rgbArr[1] + i * step));
        const b = Math.min(255, Math.round(rgbArr[2] + i * step));
        colorPalette.push(`rgb(${r},${g},${b})`);
      }
      return colorPalette;
    },
    parseRGBString(rgbString) {
      const matches = rgbString.match(/\d+/g);
      if (matches.length === 3) {
        return matches.map(Number);
      } else {
        console.error("Invalid RGB string format");
        return [0, 0, 0]; // Return default values if parsing fails
      }
    },
    convertNumber(inputNumber) {
      if (inputNumber >= 5 && inputNumber <= 9) {
        return inputNumber - 5;
      }
      return inputNumber;
    },
    thisSetEdgeHistoryDaysReloadGraphs(days) {
      this.setEdgeHistoryDaysReloadGraphs(days);
    },
  },
};
</script>

<style scoped>
#notFoundHeader {
  font-size: 28px;
  font-weight: bolder;
  margin: 0;
}
.lastComponent {
  margin-bottom: 100px;
}
.toggleBar {
  padding-bottom: 10px;
  width: 60%;
}
.searchResultContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  padding-top: 25px;
  color: white;
}
.roundedContainer {
  background-color: rgba(6, 8, 28, 0.9);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
