<template>
  <footer>
    <div id="leftFooter"></div>
    <div id="midFooter">
      <div class="linkContainer">
        <div class="linkLabel">Alby</div>
        <div class="link">
          mrquiche@getalby.com
          <copy-icon id="copyIcon" :content="this.albyName" />
        </div>
      </div>
      <div class="linkContainer">
        <div class="linkLabel">Twitter</div>
        <a class="link" href="https://twitter.com/mrquicheBTC">
          @mrquicheBTC
        </a>
      </div>
      <div class="linkContainer">
        <div class="linkLabel">Amboss</div>
        <a
          class="link"
          href="https://amboss.space/node/038cb7e8e97943babaee2d5abdb3805dc328a02fad04bb184a6e6e40ab4d9c80a6"
        >
          MrQuiche
        </a>
      </div>
      <div class="linkContainer">
        <div class="linkLabel">Telegram</div>
        <a class="link" href="https://t.me/MrQuiche"> MrQuiche </a>
      </div>
      <div class="linkContainer">
        <div class="linkLabel">Email</div>
        <a class="link" href="mailto:mrquichebtc@proton.me"
          >mrquichebtc@proton.me</a
        >
      </div>
    </div>
    <div id="rightFooter">
      <div id="version">v0.1</div>
    </div>
  </footer>
</template>

<script>
import CopyIcon from "./CopyIcon.vue";

export default {
  name: "AppFooter",
  components: {
    CopyIcon,
  },
  data() {
    return {
      albyName: "mrquiche@getalby.com",
    };
  },
};
</script>

<style scoped>
footer {
  background-color: rgba(6, 8, 28, 0.9); /* Use rgba() with an alpha value */
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 60px;
}

#leftFooter {
  width: 150px;
  display: flex;
  justify-content: left;
  padding: 10px;
}

#version {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 8px;
  padding: 10px;
  color: #333;
}
.linkLabel {
  display: flex;
  font-size: 12px;
  color: #8c8c8c;
  align-items: flex-end;
}
.linkContainer {
  display: flex;
  font-size: 12px;
  padding: 0 15px 0 15px;
  flex-direction: column;
  align-items: flex-start;
}
#midFooter {
  width: 800px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-evenly;
}

#rightFooter {
  width: 150px;
  display: flex;
  justify-content: right;
  color: white;
  font-size: 18px;
  padding-top: 5px;
}
#copyIcon {
  margin-left: 0;
}
.link {
  color: #b2b2b2;
  font-size: 15px;
}
</style>
