<template>
  <div :class="{ edgeSectionContainer: !thisIsFullScreen }">
    <div
      class="graphControlsLinksContainer"
      ref="graphControlsLinksRef"
      v-if="!!this.$store.state.shouldLoadPeerGraph"
    >
      <toggle-bar
        v-if="this.$store.state.shouldLoadNeighbors"
        :options="getNeighborChanToggleOptions"
        :label="'Neighboring Channels'"
        :default-val="this.$store.state.graphNeighborChan"
        @option-clicked="neighborChanToggleOptionClicked"
      />
      <toggle-bar
        :options="getForceLayoutToggleOptions"
        :label="'Force Layout'"
        :default-val="this.$store.state.graphForceLayout"
        @option-clicked="forceLayoutToggleOptionClicked"
      />
      <img
        class="fullScreenIcon"
        src="../assets/icon-full-screen.png"
        alt="Full Screen"
        title="Full Screen"
        v-if="!this.thisIsFullScreen"
        @click="goFullScreen"
      />
      <img
        class="fullScreenIcon"
        src="../assets/icon-exit-full-screen.png"
        alt="Exit Full Screen"
        title="Exit Full Screen"
        v-else
        @click="exitFullScreen"
      />
    </div>
    <transition name="fade">
      <div
        v-if="showPrompt && thisIsFullScreen"
        class="fullscreenPrompt"
        :class="{ 'fade-out': !showPrompt }"
      >
        PRESS ESC TO EXIT
      </div></transition
    >
    <edge-graph-diagram
      :graph-nodes="this.$store.state.graphNodes"
      :graph-edges="this.$store.state.graphEdges"
      :style="{
        bottom: `${this.edgeBasicInfoHeight}px`,
      }"
    ></edge-graph-diagram>
    <edge-basic-info
      :class="{ edgeBasicInfoFullScreen: this.thisIsFullScreen }"
      :edge-data="this.edgeData"
      ref="edgeBasicInfoRef"
    />
  </div>
</template>

<script>
import EdgeGraphDiagram from "./EdgeGraphDiagram.vue";
import EdgeBasicInfo from "./EdgeBasicInfo.vue";
import ToggleBar from "./ToggleBar.vue";

export default {
  name: "EdgeGraphSection",
  props: ["edgeData"],
  components: {
    ToggleBar,
    EdgeBasicInfo,
    EdgeGraphDiagram,
  },
  inject: [
    "noSearchText",
    "searchButNoResult",
    "isFullScreenGraph",
    "convertPeersToDisplayChannels",
    "convertEdgesToDisplayChannels",
    "calculateEdgeWidth",
    "calculateNodeRadius",
    "getNumberFormattedCommas",
  ],
  data() {
    return {
      showPrompt: true,
      edgeBasicInfoHeight: 0,
    };
  },
  mounted() {
    this.$refs.graphControlsLinksRef.focus();
    // this.updateHeight();
    setTimeout(() => {
      this.showPrompt = false;
    }, 2000);
    if (this.isNeighborsExist) {
      // this.getNeighborEdges();
      this.convertEdgesToDisplayChannels(this.$store.state.neighborEdges);
    }
  },
  computed: {
    getNeighborChanToggleOptions() {
      return [
        this.$store.state.graphNeighborChanHide,
        this.$store.state.graphNeighborChanShow,
      ];
    },
    getForceLayoutToggleOptions() {
      return [
        this.$store.state.graphForceLayoutOn,
        this.$store.state.graphForceLayoutOff,
      ];
    },
    thisNoSearchText() {
      return this.noSearchText();
    },
    thisSearchButNoResult() {
      return this.searchButNoResult();
    },
    thisIsFullScreen() {
      // return this.isFullScreenGraph();

      const result =
        !this.thisNoSearchText &&
        !this.thisSearchButNoResult &&
        this.$store.state.searchedNode &&
        this.$store.state.searchedNode.pubkey &&
        this.$store.state.searchedNode.pubkey.trim() !== "" &&
        this.$route.path ===
          "/node/full/" + this.$store.state.searchedNode.pubkey;
      return result;
    },
    isNeighborsExist() {
      return this.$store.state.networkLayerCount > 0;
    },
  },
  watch: {
    "$store.state.selectedEdge": {
      handler() {
        this.updateHeight();
      },
      deep: true,
    },
    "$store.state.neighborEdges": {
      handler(newValue) {
        // Check if searchedNode is defined and has a pubkey property
        if (newValue) {
          this.thisConvertEdgesToDisplayChannels(
            this.$store.state.neighborEdges
          );
          if (
            this.$store.state.peers.length > 0 &&
            !this.isNeighborsExist &&
            Array.isArray(newValue) &&
            newValue.length === 0
          ) {
            this.thisConvertPeersToDisplayChannels(this.$store.state.peers);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    updateHeight() {
      this.$nextTick(() => {
        if (this.$refs.edgeBasicInfoRef) {
          this.edgeBasicInfoHeight =
            this.$refs.edgeBasicInfoRef.$el.clientHeight;
        } else {
          this.edgeBasicInfoHeight = 0;
        }
      });
    },
    neighborChanToggleOptionClicked(option) {
      if (option === this.$store.state.graphNeighborChanHide) {
        this.$store.dispatch("resetGraphNeighborChan");
        this.removeNeighborEdges();
      } else if (option === this.$store.state.graphNeighborChanShow) {
        this.$store.dispatch("setGraphNeighborChan");

        this.getNeighborEdges();
      }
    },
    forceLayoutToggleOptionClicked(option) {
      if (option === this.$store.state.graphForceLayoutOff) {
        this.$store.dispatch("resetGraphForceLayout");
      } else if (option === this.$store.state.graphForceLayoutOn) {
        this.$store.dispatch("setGraphForceLayout");
      }
    },
    goFullScreen() {
      if (!this.thisIsFullScreen) {
        this.$router.push({
          name: "full",
          params: { pubkey: this.$store.state.searchedNode.pubkey },
        });
      }
    },
    exitFullScreen() {
      if (this.thisIsFullScreen) {
        this.$router.go(-1);
      }
    },
    getNeighborEdges() {
      this.$store.dispatch("incrNetworkLayerCount");
      if (this.$store.state.peers < 30) {
        this.$store.dispatch("fetchNodeEdgesByPubkeys");
      } else {
        this.$store.dispatch("fetchEdgesBySessionId");
      }
    },
    removeNeighborEdges() {
      this.$store.dispatch("clearNeighborEdges");
      this.$store.dispatch("resetNetoworkLayerCount");
    },
    thisConvertPeersToDisplayChannels(peers) {
      this.convertPeersToDisplayChannels(peers);
    },
    thisConvertEdgesToDisplayChannels(edges) {
      this.convertEdgesToDisplayChannels(edges);
    },
  },
};
</script>

<style scoped>
.edgeSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
}
@media (min-width: 1000px) {
  .edgeSectionContainer {
    min-width: 1000px;
  }
}
.edgeBasicInfoFullScreen {
  margin: 0;
  color: white;
  width: 90%;
  padding-bottom: 10px;
  justify-content: center;
}
.fullscreenPrompt,
.fade-enter-active,
.fade-leave-active {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: opacity 1s;
  z-index: 9999;
}
.fade-out,
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fullScreenIcon {
  padding: 2px 2px 2px 15px;
  cursor: pointer;
}
.graphControlsLinksContainer {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  padding: 15px 0 15px 0;
}
</style>
