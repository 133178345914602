<template>
  <div
    class="edgeGrid"
    :class="{ edgeGridFullScreen: this.thisIsFullScreen }"
    v-if="this.edgeExists"
    ref="edgeGridRef"
  >
    <div
      class="edgeRow"
      v-for="(row, i) in getEdgeRowFields"
      :key="row"
      ref="edgeRows"
      :style="{
        borderBottom: i === 0 ? '2px solid #707070' : 'none',
        width: i === 0 ? '530px' : '100%',
      }"
    >
      <div
        class="basicInfoFieldContainer"
        v-for="(field, j) in row"
        :key="j"
        :style="{ width: getColumnWidth(i, j) + 'px' }"
      >
        <basic-field
          :label="field.label"
          :value="
            getSelectedEdgeValueByName(i === 0 ? field.name : field.newName)
          "
        ></basic-field>
      </div>
    </div>
  </div>
</template>

<script>
import BasicField from "./BasicField.vue";
export default {
  name: "EdgeBasicInfo",
  components: { BasicField },
  props: ["edgeData"],
  inject: ["noSearchText", "searchButNoResult", "edgeFields"],
  data() {
    return {
      columnWidths: [[], [], []],
    };
  },
  watch: {
    "this.edgeData": {
      handler(newValue) {
        if (newValue) {
          this.updateColumnWidths();
        }
      },
      deep: true, // Watch for changes deeply in the object
    },
  },
  computed: {
    thisEdgeFields() {
      return this.edgeFields();
    },
    thisNoSearchText() {
      return this.noSearchText();
    },
    thisSearchButNoResult() {
      return this.searchButNoResult();
    },
    thisIsFullScreen() {
      // return this.isFullScreenGraph();

      const result =
        !this.thisNoSearchText &&
        !this.thisSearchButNoResult &&
        this.$store.state.searchedNode &&
        this.$store.state.searchedNode.pubkey &&
        this.$store.state.searchedNode.pubkey.trim() !== "" &&
        this.$route.path ===
          "/node/full/" + this.$store.state.searchedNode.pubkey;
      return result;
    },
    edgeFieldsNoPeer() {
      return this.thisEdgeFields.map((item) => {
        // Check if category is "peer" and label starts with "Peer "
        if (item.category === "peer" && item.label.startsWith("Peer ")) {
          // Remove "Peer " from the beginning of the label
          return { ...item, label: item.label.slice(5) };
        }
        return item;
      });
    },
    isSelectedEdgeNeighbor() {
      return (
        !!this.$store.state.searchedNode &&
        this.edgeExists &&
        this.$store.state.searchedNode.pubkey !== this.edgeData.node1Pub &&
        this.$store.state.searchedNode.pubkey !== this.edgeData.node2Pub
      );
    },
    getEdgeRowFields() {
      return [
        this.basicEdgeFields,
        this.peerNodeEdgeFields,
        this.selectedNodeEdgeFields,
      ];
    },
    edgeExists() {
      return (
        !!this.edgeData &&
        !!this.edgeData.channelId &&
        this.edgeData.channelId !== ""
      );
    },
    basicEdgeFields() {
      // Filter node agnostic edgeFields
      if (this.edgeExists) {
        return this.thisEdgeFields.filter(
          (field) => field.active && field.category === ""
        );
      } else {
        return this.thisEdgeFields;
      }
    },
    peerNodeEdgeFields() {
      // Filter edgeFields based on the 'peer' category
      const edgesFields = this.isSelectedEdgeNeighbor
        ? this.edgeFieldsNoPeer
        : this.thisEdgeFields;
      if (this.edgeExists) {
        return edgesFields
          .map((field) => {
            // Construct the peer field name based on the selected node's pubkey
            const newName = this.$store.state.searchedNode
              ? field.name.replace(
                  /\d+/,
                  this.edgeData.node1Pub !==
                    this.$store.state.searchedNode.pubkey
                    ? "1"
                    : "2"
                )
              : field.name;

            return {
              ...field,
              newName, // Add the constructed peer field name to the returned object
            };
          })
          .filter((field) => field.active && field.category === "peer");
      } else {
        return this.thisEdgeFields;
      }
    },
    selectedNodeEdgeFields() {
      // Filter edgeFields based on the 'selected' category
      if (this.edgeExists) {
        return this.thisEdgeFields
          .map((field) => {
            // Construct the selected field name based on the selected node's pubkey
            const newName = this.$store.state.searchedNode
              ? field.name.replace(
                  /\d+/,
                  this.edgeData.node1Pub ===
                    this.$store.state.searchedNode.pubkey
                    ? "1"
                    : "2"
                )
              : field.name;

            return {
              ...field,
              newName, // Add the constructed peer field name to the returned object
            };
          })
          .filter((field) => field.active && field.category === "selected");
      } else {
        return this.thisEdgeFields;
      }
    },
  },
  methods: {
    getSelectedEdgeValueByName(name) {
      return this.edgeData[name];
    },
    getColumnWidth(i, j) {
      const rows = this.$refs.edgeRows;
      if (rows) {
        if (!this.columnWidths[i][j]) {
          return "auto";
        } else if (i >= 2) {
          this.columnWidths[i][j] = this.columnWidths[i - 1][j];
        }
        return this.columnWidths[i][j];
      }
    },
    updateColumnWidths() {
      this.$nextTick(() => {
        const rows = this.$refs.edgeRows;
        if (rows) {
          for (let row = 0; row < rows.length; row++) {
            const cells = rows[row].children;
            for (let i = 0; i < cells.length; i++) {
              const width = cells[i].getBoundingClientRect().width;
              this.columnWidths[row][i] = width;
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.edgeGrid {
  width: 100%;
  text-align: left;
  margin-top: 16px;
  padding: 0 16px 0;
  display: grid;
  bottom: 0;
  gap: 16px;
}
.edgeGridFullScreen {
  position: absolute;
}
.edgeRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.basicInfoFieldContainer {
  margin: 0 16px 0;
}
</style>
