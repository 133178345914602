// main.js
import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router.js";
import store from "@/store.js";
import VNetworkGraph from "v-network-graph";
import "v-network-graph/lib/style.css";

const app = createApp(App);

app.use(VNetworkGraph);
app.use(router);
app.use(store); // Ensure that Vue is used before Vuex

app.mount("#app");
