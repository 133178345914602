// router.js
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView";
import NodeView from "@/views/NodeView";
import EdgeRecord from "@/views/EdgeView";
import EdgeGraphFull from "@/views/EdgeGraphFull";
import NotFound from "@/components/NotFound";

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/node/:pubkey",
      name: "node",
      component: NodeView,
    },
    {
      path: "/channel/:chanId",
      name: "channel",
      component: EdgeRecord,
    },
    {
      path: "/node/full/:pubkey",
      name: "full",
      component: EdgeGraphFull,
    },
    // catchall 404
    {
      path: "/:catchAll(.*)",
      name: "notfound",
      component: NotFound,
    },
  ],
});

export default router;
