<template>
  <div>
    <canvas class="nodeLineChart" ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "NodeHistChart",
  props: ["chartData", "chartLabels", "chartOptions", "lineColor"],
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      if (!this.chartData || !this.chartLabels) {
        console.error("Invalid chart data. Chart not rendered.");
        return;
      }

      const ctx = this.$refs.chart.getContext("2d");

      // Convert date labels to actual dates
      const labels = this.chartLabels.map((label) => {
        const date = new Date(label);
        return date;
      });
      // Show only the month and day on x-axis tick labels
      const formattedXLabels = labels.map((date) => {
        const month = date.toLocaleString("default", { month: "short" });
        const day = date.getDate();
        return `${month} ${day}`;
      });

      new Chart(ctx, {
        type: "line",
        data: {
          labels: formattedXLabels,
          datasets: this.chartData,
        },
        options: {
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: true, // Automatically skip ticks to prevent overlapping
                  maxTicksLimit: 10, // Maximum number of ticks to display
                  maxRotation: 45,
                  minRotation: 45,
                },
              },
            ],
            yAxes: [
              {
                title: {
                  display: true,
                  text: "channel count",
                },
                position: "left",
                id: "y",
                ticks: {
                  callback: function (value, index, values) {
                    // Calculate the range of values
                    const range = Math.max(...values) - Math.min(...values);

                    // Determine the number of significant digits
                    const significantDigits = Math.floor(Math.log10(range)) + 1;

                    if (significantDigits > 9) {
                      return (
                        (value / 1000000000)
                          .toFixed(1)
                          .replace(/\.0$/, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "B"
                      );
                    } else if (significantDigits > 6) {
                      return (
                        (value / 1000000)
                          .toFixed(1)
                          .replace(/\.0$/, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M"
                      );
                    } else if (significantDigits > 3) {
                      return (
                        (value / 1000)
                          .toFixed(1)
                          .replace(/\.0$/, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "K"
                      );
                    } else {
                      return Math.round(value)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                  },
                },
              },
              {
                title: {
                  display: true,
                  text: "sats",
                },
                id: "ySats",
                position: "right",
                grid: {
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                ticks: {
                  callback: function (value, index, values) {
                    // Calculate the range of values
                    const range = Math.max(...values) - Math.min(...values);

                    // Determine the number of significant digits
                    const significantDigits = Math.floor(Math.log10(range)) + 1;

                    if (significantDigits > 9) {
                      return (
                        (value / 1000000000)
                          .toFixed(1)
                          .replace(/\.0$/, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "B"
                      );
                    } else if (significantDigits > 6) {
                      return (
                        (value / 1000000)
                          .toFixed(1)
                          .replace(/\.0$/, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M"
                      );
                    } else if (significantDigits > 3) {
                      return (
                        (value / 1000)
                          .toFixed(1)
                          .replace(/\.0$/, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "K"
                      );
                    } else {
                      return Math.round(value)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || "";
                if (label) {
                  label += ": ";
                }
                label += tooltipItem.yLabel
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return label;
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style>
.nodeLineChart {
  height: 400px;
  width: 600px;
  /*margin: 0 50px 0 50px;*/
}
</style>
